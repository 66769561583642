export const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    fixed: 'left',
    width: 60,
    customRender: (value, item, index) => index + 1,
  },
  {
    title: '拣货单号',
    dataIndex: 'number',
    fixed: 'left',
    width: 180,
    ellipsis: true,
    scopedSlots: { customRender: 'number' },
  },
  {
    title: "打印状态",
    dataIndex: "is_print",
    width: 80,
    customRender: (value) => {
      return value ? "已打印" : "未打印";
    },
  },
  // {
  //   title: '波次拣货单号',
  //   dataIndex: 'merge_order_number',
  //   width: 180,
  //   ellipsis: true,
  //   scopedSlots: { customRender: 'merge_order_number' },
  // },
  {
    title: '出库通知单号',
    dataIndex: 'delivery_order_number',
    width: 180,
    ellipsis: true,
    scopedSlots: { customRender: 'delivery_order_number' },
  },
  {
    title: '客户单号',
    dataIndex: 'external_number',
    width: 180,
    ellipsis: true,
  },
  {
    title: '客户',
    dataIndex: 'client_name',
    width: 120,
    ellipsis: true,
  },
  {
    title: '状态',
    dataIndex: 'status',
    width: 100,
    ellipsis: true,
    customRender: (value, item, index) => {
      return item.is_completed ? '已完成' : '进行中'
    },
  },
  {
    title: '应拣数量',
    dataIndex: 'total_quantity',
    width: 100,
    ellipsis: true,
  },
  {
    title: '剩余数量',
    dataIndex: 'remain_quantity',
    width: 100,
    ellipsis: true,
  },
  {
    title: '创建人',
    dataIndex: 'creator_name',
    width: 120,
    ellipsis: true,
  },
  {
    title: '创建时间',
    dataIndex: 'create_time',
    width: 180,
    ellipsis: true,
  },
  {
    title: '操作',
    dataIndex: 'action',
    fixed: 'right',
    width: 200,
    scopedSlots: { customRender: 'action' },
  },
]