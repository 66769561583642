<template>
  <div>
    <a-row gutter="16">
      <a-col :span="24" :md="8" :xl="5" style="max-width: 256px; margin-bottom: 12px">
        <a-range-picker :value="[searchForm.start_date, searchForm.end_date]" @change="onChangePicker" />
      </a-col>
      <a-col :span="24" :md="6" :xl="4" style="max-width: 256px; margin-bottom: 12px">
        <client-select v-model="searchForm.client" placeholder="客户" @change="search"></client-select>
      </a-col>
      <a-col :span="24" :md="8" :xl="4" style="max-width: 256px; margin-bottom: 12px">
        <a-select v-model="searchForm.is_completed" placeholder="状态" allowClear style="width: 100%" @change="search">
          <a-select-option key="0" :value="false">进行中</a-select-option>
          <a-select-option key="1" :value="true">已完成</a-select-option>
        </a-select>
      </a-col>
      <a-col :span="24" :md="8" :xl="4" style="max-width: 256px; margin-bottom: 12px">
        <a-input v-model="searchForm.search" placeholder="单据编号, 客户单号, 备注" allowClear @pressEnter="search" />
      </a-col>
      <a-col :span="24" :md="8" :xl="6" style="max-width: 100px; margin-bottom: 12px">
        <a-button type="primary" icon="search" @click="search">查询</a-button>
      </a-col>

      <a-col :span="24" :md="8" :xl="8" style="margin-bottom: 12px">
        <a-button-group>
          <!-- <a-button icon="file-excel" @click="downloadTemplate">模板下载</a-button>
          <a-upload name="file" :showUploadList="false" :customRequest="importExcel">
            <a-button icon="upload">导入</a-button>
          </a-upload> -->
          <a-button icon="download" @click="exportExcel">导出</a-button>
          <a-button :disabled="!selectedRowKeys.length" @click="mergeOrder">合并</a-button>
        </a-button-group>
      </a-col>
    </a-row>

    <div>
      <a-table
        rowKey="id"
        size="small"
        :columns="columns"
        :data-source="items"
        :pagination="pagination"
        :loading="loading"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :scroll="{ x: 1620, y: 480 }"
        @change="onChangeTable"
      >
        <template slot="delivery_order_number" slot-scope="value, item, index">
          <a @click="detail(item)">{{ value }}</a>
        </template>
        <template slot="number" slot-scope="value, item, index">
          <a @click="handleDetail(item.id)">{{ value }}</a>
        </template>
        <template slot="merge_order_number" slot-scope="value, item, index">
          <a @click="handleDetail(item.merge_order)">{{ value }}</a>
        </template>
        <div slot="action" slot-scope="value, item, index">
          <a-button-group size="small">
            <a-button @click="handleDetail(item.id)">详情</a-button>
            <a-button type="primary" @click="handlePicking(item)">拣货</a-button>
            <a-button type="primary" @click="quickPicking(item)">拣货出库</a-button>
            <!-- <a-popconfirm title="确定完成吗?" @confirm="complete(item)">
              <a-button v-if="!item.is_completed" type="primary">完成</a-button>
            </a-popconfirm> -->
          </a-button-group>
        </div>
      </a-table>
    </div>

    <a-modal v-model="importLoading" :footer="null" :maskClosable="false" :closable="false">
      <div><a-spin style="margin-right: 12px" />正在导入中, 请等待...</div>
    </a-modal>
  </div>
</template>

<script>
import { exportExcel } from "@/utils/excel";
import { pickingOrdersExport } from "@/api/export";
import { pickingOrderList, pickingMergeCreate, pickingOrderComplete } from "@/api/stockOut";
import { columns } from "./columns";
import { deliveryOrderTemplate, deliveryOrderImport } from "@/api/import";
import moment from "moment";

export default {
  components: {
    ClientSelect: () => import("@/components/ClientSelect/"),
  },
  data() {
    return {
      columns,
      pagination: { current: 1, total: 0, pageSize: 48, showTotal: (total) => `共 ${total} 条` },
      searchForm: { page: 1, page_size: 48, is_completed: false, is_void: false, is_merge_order: false },
      loading: false,
      items: [],
      selectedRowKeys: [],
      selectionRows: [],
      importLoading: false,
    };
  },
  computed: {
    currentWarehouse() {
      return this.$store.state.user.currentWarehouse;
    },
  },
  methods: {
    initData() {
      this.searchForm.warehouse = this.currentWarehouse;
      this.searchForm = { ...this.searchForm, ...this.$functions.parseParams(this.$route.query) };
      this.list();
    },
    handleDetail(id) {
      this.$router.push({ path: "/stock_out/picking_task_detail", query: { id } });
    },
    handlePicking(item) {
      let id = item.merge_order_number ? item.merge_order : item.id;
      this.$router.push({ path: "/stock_out/picking_record_create", query: { id, area: item.area } });
    },
    quickPicking(item) {
      this.$router.push({ path: "/stock_out/quick_picking_record", query: { id: item.id, area: item.area } });
    },
    onChangeTable(pagination, filters, sorter) {
      this.pagination = pagination;
      this.searchForm.page = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    onChangePicker(date, dateString) {
      let startDate = date[0],
        endDate = date[1];
      this.searchForm.start_date = startDate ? startDate.format("YYYY-MM-DD") : undefined;
      this.searchForm.end_date = endDate ? endDate.format("YYYY-MM-DD") : undefined;
      this.search();
    },
    search() {
      this.pagination.current = 1;
      this.searchForm.page = 1;
      this.list();
    },
    list() {
      let searchForm = { ...this.searchForm };
      if (searchForm.end_date) {
        searchForm.end_date = moment(searchForm.end_date).add(1, "days").format("YYYY-MM-DD");
      }

      this.loading = true;
      this.$router.push({ query: this.searchForm });
      pickingOrderList(searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;
        })
        .finally(() => {
          this.selectedRowKeys = [];
          this.loading = false;
        });
    },
    onCreatePickingRecord() {
      this.list();
    },
    mergeOrder() {
      pickingMergeCreate({ picking_orders: this.selectedRowKeys })
        .then((data) => {
          this.$message.success("创建成功");
          this.list();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    complete(item) {
      pickingOrderComplete({ id: item.id }).then(() => {
        this.$message.success("完成成功");
        this.list();
      });
    },
    onSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectionRows = selectionRows;
    },
    onClearSelected() {
      this.selectedRowKeys = [];
      this.selectionRows = [];
    },
    exportExcel() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning("请选择要导出的拣货单!");
        return false;
      }
      pickingOrdersExport({ ids: this.selectedRowKeys })
        .then((resp) => {
          exportExcel(resp, "拣货单列表");
        })
        .catch((err) => {
          this.$message.error(err.response.data.error);
        });
    },
    downloadTemplate() {
      deliveryOrderTemplate()
        .then((resp) => {
          exportExcel(resp, "出库通知单导入模板");
        })
        .catch((err) => {
          this.$message.error(err.response.data.error);
        });
    },
    importExcel(item) {
      let data = new FormData();
      data.append("file", item.file);
      this.importLoading = true;
      setTimeout(() => {
        deliveryOrderImport(data)
          .then(() => {
            this.$message.success("导入成功");
            this.list();
          })
          .catch((err) => {
            alert("导入错误:\n" + err.response.data.join("\n"));
          })
          .finally(() => {
            this.importLoading = false;
          });
      }, 1000);
    },
    detail(item) {
      this.$router.push({ path: "/stock_out/delivery_task_detail", query: { id: item.delivery_order } });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
